import { Component, OnInit } from "@angular/core";
import { ILicenseInfo } from "app/API/v1/License/api.license.common";
import { AccountService } from "app/entry/account.service";
import { concatMap } from "rxjs/operators";
import { UserService } from "../admin/user/user.service";
import { LicenseCategory } from "./license.data";
import { LicenseService } from "./license.service";

@Component({
    templateUrl: './account-license.component.html',
    styleUrls: ['./account-license.component.css']
})
export class AccountLicenseComponent implements OnInit {
    _loading: boolean = false;
    _accountType: string;
    _accountLicenseList: { name: string, usage: number, total: number }[] = [];
    _remoteCtrlLicense: { name: string, usage: number, total: number, expiryDate?: Date, licenseKeyList: ILicenseInfo[] } = { name: 'Remote control', usage: 0, total: 0, expiryDate: null, licenseKeyList: [] };
    _showRemoteCtrlDetailLicenses: boolean = false;

    constructor(private accountSvc: AccountService, private licenseSvc: LicenseService, private userSvc: UserService) { }

    ngOnInit(): void {
        this._loading = true;

        this.licenseSvc.getLicenseByAccount().pipe(
            concatMap((res: {
                [licenseCategory: string]: {
                    detail: {
                        [licenseType: string]: {
                            licenseKeyList: ILicenseInfo[];
                        }
                    }
                }
            }) => {
                if (res[LicenseCategory.RemoteControl]) {
                    this._remoteCtrlLicense.licenseKeyList = res[LicenseCategory.RemoteControl].detail?.['remote-control']?.licenseKeyList;
                    this._remoteCtrlLicense.total = this._remoteCtrlLicense.licenseKeyList.length;
                    this._remoteCtrlLicense.usage = this._remoteCtrlLicense.licenseKeyList.filter(license => license.licenseKeyActivatedDate).length;
                    this._remoteCtrlLicense.expiryDate = this._remoteCtrlLicense.licenseKeyList.reduce((prev: Date, curr: ILicenseInfo) =>
                        !prev || new Date(curr.licenseKeyExpiryDate).getTime() > prev.getTime() ? new Date(curr.licenseKeyExpiryDate) : prev,
                        null
                    );
                }

                return this.userSvc.getEnterpriseLicenseUsage();
            })
        ).subscribe((res: { license?: { [roleName: string]: { limit: number, usage: number } }, isFault: boolean, errorMessage?: string }) => {
            if (!res.isFault) {
                this._accountLicenseList = Object.keys(res.license).map(roleName => ({ name: roleName, usage: res.license[roleName].usage, total: res.license[roleName].limit }));
            }

            this._loading = false;
        })
    }

    get isEnterprise(): boolean {
        return this.accountSvc.isEnterprise();
    }

    showRemoteCtrlLicense(show: boolean): void {
        this._showRemoteCtrlDetailLicenses = show;
    }
}