<button #btnMobile type="button" [disabled]="!_isRemoteCtrlPreCheckSupport" class="d-none">Mobile layout</button>
<div class="d-flex">
    <div class="btn-group">
        <button id="dropdownAdvancedControlLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
            data-toggle="dropdown" [disabled]="_loading">
            <i class="fas fa-cogs mr-1 d-inline-block d-md-none"></i>
            <span class="d-none d-md-inline-block">Batch actions</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-left option-list" aria-labelledby="dropdownAdvancedControlLink">
            <li *ngIf="supportBasicConfig()" id="id_home_do_batchBasicConfig" data-toggle="modal"
                data-target="#devPickupFuncModalHost" class="dropdown-item"
                (click)="playDevFunc(devFuncSvc.FUNCNAME_MULTI_BASIC_CONFIG)">
                <span class="icon fas fa-sliders-h"></span>
                Basic configuration
            </li>
            <li *ngIf="supportDeviceSecurity()" id="id_home_do_changeDevPwd" data-toggle="modal"
                data-target="#devPickupFuncModalHost" class="dropdown-item"
                (click)="playDevFunc(devFuncSvc.FUNCNAME_SECURITY)">
                <span class="icon fas fa-lock"></span>
                Update security password
            </li>
            <li *ngIf="supportFirmwareUpdate()" id="id_home_do_batchFirmwareUpdate" data-toggle="modal"
                data-target="#devPickupFuncModalHost" class="dropdown-item"
                (click)="playDevFunc(devFuncSvc.FUNCNAME_FIRMWARE)">
                <span class="icon fas fa-upload"></span>
                Update firmware
            </li>
            <li *ngIf="supportIAdeaCareAPKUpdate()" id="id_home_do_batchAPKUpdate" data-toggle="modal"
                data-target="#devPickupFuncModalHost" class="dropdown-item"
                (click)="playDevFunc(devFuncSvc.FUNCNAME_APK)">
                <span class="icon fas fa-external-link-square-alt"></span>
                Update IAdeaCare APK
            </li>
            <li *ngIf="supportReboot()" id="id_home_do_batchReboot" data-toggle="modal"
                data-target="#devPickupFuncModalHost" class="dropdown-item"
                (click)="playDevFunc(devFuncSvc.FUNCNAME_REBOOT)">
                <span class="icon fas fa-redo-alt"></span>
                Reboot
            </li>
            <li *ngIf="supportTroubleshoot()" id="id_home_do_batchTroubleshoot" data-toggle="modal"
                data-target="#devPickupFuncModalHost" class="dropdown-item"
                (click)="playDevFunc(devFuncSvc.FUNCNAME_ISSUE_REPORT)">
                <span class="icon fas fa-tools"></span>
                Troubleshoot
            </li>
            <li *ngIf="supportClearCache()" id="id_home_do_batchClearCache" data-toggle="modal"
                data-target="#devPickupFuncModalHost" class="dropdown-item"
                (click)="playDevFunc(devFuncSvc.FUNCNAME_CLEARCACHE)">
                <span class="icon fas fa-broom"></span>
                Clear cache
            </li>
            <li *ngIf="supportClearAppData()" id="id_home_do_batchClearAppData" data-toggle="modal"
                data-target="#devPickupFuncModalHost" class="dropdown-item"
                (click)="playDevFunc(devFuncSvc.FUNCNAME_CLEARAPPDATA)">
                <span class="icon fas fa-broom"></span>
                Clear app data
            </li>
            <li id="id_home_do_attachDeviceLabel" data-toggle="modal" data-target="#devLabelFuncModalHost"
                class="dropdown-item" (click)="addDeviceLabel()">
                <span class="icon fas fa-tag"></span>
                Add label
            </li>
            <li *ngIf="supportBatchCAScript()" id="id_home_do_batchScript" data-toggle="modal"
                data-target="#devPickupFuncModalHost" class="dropdown-item"
                (click)="playDevFunc(devFuncSvc.FUNCNAME_SCRIPT)">
                <span class="icon fas fa-scroll"></span>
                Script
            </li>
        </ul>
    </div>
    <!-- refresh device -->
    <button id="id_home_do_refreshAllDevices" type="button" class="btn btn-sm btn-action btn-light ml-2"
        [disabled]="_refreshCounter > 0 || _loadingDevices || _exporting" data-toggle="tooltip"
        title='{{ "key-dev-refresh" | translate | capitalFirstWord }}' (click)="refreshDevices()">
        <i [class.rotate]="_refreshCounter > 0" class="fas fa-sync-alt mr-1"></i>
        <span class="d-none d-md-inline-block">{{ "key-dev-refresh" | translate }}</span>
        <ng-template [ngIf]="_refreshCounter > 0"> - {{ _refreshCounter }} s</ng-template>
    </button>
    <a #reportLink class="d-none"></a>
    <!-- export device -->
    <button id="id_home_do_exportDevices" type="button" class="btn btn-sm btn-action btn-light ml-2"
        [disabled]="_loadingDevices || _exporting" title="export" (click)="exportDevices()">
        <i class="fas fa-file-csv mr-1"></i>
        <span class="d-none d-md-inline-block">Export</span>
    </button>
    <!-- table view column selector -->
    <div *ngIf="_viewType === _enumViewType.table" class="ml-2">
        <button id="dropdownTableColumnLink" type="button" class="btn btn-sm btn-action btn-light h-100"
            [disabled]="_loading || _exporting" data-toggle="dropdown">
            <span class="fas fa-columns mr-1"></span>
            <span class="d-none d-md-inline-block">Columns</span>
        </button>
        <ul class="dropdown-menu option-list" data-backdrop="static" data-keyboard="false"
            aria-labelledby="dropdownTableColumnLink">
            <ng-template ngFor let-devCol [ngForOf]="_tableColumnList">
                <ng-template [ngIf]="devCol.allowChoose">
                    <li class="dropdown-item" (click)="$event.stopPropagation()">
                        <input type="checkbox" [id]="'id_home_do_showCol_' + devCol.id" class="cmn-checkbox"
                            [checked]="devCol.show" (change)="changeTableColumnOption(devCol)">
                        <label [for]="'id_home_do_showCol_' + devCol.id" (click)="$event.stopPropagation()">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Show column {{ devCol.langKey | translate }} on table</span>
                        </label>
                        <span class="ml-2">{{ devCol.langKey | translate | capitalFirstWord }}</span>
                    </li>
                </ng-template>
            </ng-template>
        </ul>
    </div>
    <div class="ml-auto d-flex">
        <div [ngSwitch]="_viewType">
            <ng-template [ngSwitchCase]="_enumViewType.table">
                <button id="id_home_do_switchGridView" type="button" [disabled]="_loading || _exporting"
                    class="btn btn-sm btn-action btn-light" data-toggle="tooltip"
                    title='{{ "key-view-grid" | translate | capitalFirstWord }}'
                    (click)="changeView(_enumViewType.grid)">
                    <i class="fas fa-th-large"></i>
                    <span class="sr-only">{{ "key-view-grid" | translate }}</span>
                </button>
            </ng-template>
            <ng-template [ngSwitchCase]="_enumViewType.grid">
                <button id="id_home_do_switchListView" type="button"
                    class="btn btn-sm btn-action btn-light layout-desktop" [disabled]="_loading || _exporting"
                    data-toggle="tooltip" title='{{ "key-view-table" | translate | capitalFirstWord }}'
                    (click)="changeView(_enumViewType.table)">
                    <i class="fas fa-list"></i>
                    <span class="sr-only">{{ "key-view-table" | translate }}</span>
                </button>
            </ng-template>
        </div>
        <div class="dropdown-wrapper btn-group ml-2">
            <button id="dropdownPageAmountLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="_loading || _exporting">
                {{ _numberInPage }}
            </button>
            <ul class="dropdown-menu option-list" aria-labelledby="dropdownPageAmountLink">
                <li *ngFor="let numberInPageOption of NUMBER_IN_PAGE_OPTIONS" class="dropdown-item"
                    [class.selected]="numberInPageOption === _numberInPage"
                    (click)="changeNumberInPage(numberInPageOption)">
                    {{ numberInPageOption }}
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="d-flex mt-1">
    <div class="input-group">
        <input #search type='text' class="form-control form-control-sm search-box" [class.active]="_searchInfo?.value" [disabled]="_loading" name='search'
            placeholder="Search by {{_currentSearchKey.langKey | translate | capitalFirstWord}} ..."
            aria-label="Search devices">
        <div class="input-group-append">
            <button id="dropdownSearchOptionLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
                [disabled]="_loading" data-toggle="dropdown">
                <span class="fas fa-search"></span>
                <span class="sr-only">{{ _currentSearchKey?.langKey | translate | capitalFirstWord }}</span>
            </button>
            <ul class="dropdown-menu option-list" data-backdrop="static" data-keyboard="false"
                aria-labelledby="dropdownSearchOptionLink">
                <li *ngFor="let searchKey of _searchKeyList" class="dropdown-item"
                    [class.active]="searchKey.key === _currentSearchKey?.key" (click)="chooseSearchKey(searchKey)">
                    {{ searchKey.langKey | translate | capitalFirstWord }}
                </li>
            </ul>
        </div>
    </div>
</div>
<div #dragImgSubstitute class="dragHint">
    {{ _selectedDeviceAmountByFilter }} device(s) are selected.
</div>
<div *ngIf="!_loading; else templateLoading" class="mt-2">
    <div [ngSwitch]="_viewType">
        <ng-template [ngSwitchCase]="_enumViewType.table">
            <div class="list-group">
                <div class="list-group-item list-group-header">
                    <div class="row align-items-center">
                        <ng-template ngFor let-col [ngForOf]="_tableColumnList">
                            <div *ngIf="col.allowShow && col.show" class="col title d-flex">
                                <ng-template [ngIf]="col.key === constantSvc.DEVKEY_FAKE_DISPLAYNAME">
                                    <input type="checkbox" id="cb-th" class="cmn-checkbox cmn-checkbox-white"
                                        [checked]="_bAllDeviceSelectedUnderCurrentPage"
                                        (change)="selectAllDevices($event.target.checked)">
                                    <label for="cb-th" class="mr-2">
                                        <svg>
                                            <use xlink:href="#checkmark" />
                                        </svg>
                                        <span class="sr-only">Select all players</span>
                                    </label>
                                </ng-template>
                                <span>{{ col.langKey | translate | capitalFirstWord }}</span>
                                <span *ngIf="col.allowSort" class="sorter">
                                    <i class="fas fa-caret-up sort-icon descend"
                                        [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.descend ? 'active' : ''"
                                        (click)="sortDescend(col.key)"></i>
                                    <i class="fas fa-caret-down sort-icon ascend"
                                        [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                                        (click)="sortAscend(col.key)"></i>
                                </span>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="list-group-content list-group-hoverless" [class.list-group-shrink]="isTableViewShrink()">
                    <!-- devices -->
                    <div *ngFor="let device of _displayDevListByPage; let recordIndex = index"
                        class="list-group-item list-group-item-action" [class.search-active]="_isAdvanceFilterApplied || _filter?.onlineStatus">
                        <div class="row align-items-center" draggable="true" (dragstart)="onDragStart($event)">
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_FAKE_DISPLAYNAME].show"
                                class="col d-flex align-items-center text-break">
                                <input [id]="'cb-td-' + recordIndex" type="checkbox" class="cmn-checkbox"
                                    [checked]="device.isSelect"
                                    (change)="selectDevice(device, $event.target.checked)" />
                                <label [for]="'cb-td-' + recordIndex">
                                    <svg>
                                        <use xlink:href="#checkmark" />
                                    </svg>
                                    <span class="sr-only">Select device {{device.virtualName}}</span>
                                </label>
                                <div [ngSwitch]="device.onlineStatus" class="ml-2">
                                    <ng-template [ngSwitchCase]="_enumOnlineType.Online">
                                        <div class="status-item">
                                            <div class="ball status-Online" data-toggle="tooltip"
                                                [title]="_enumOnlineType[device.onlineStatus]">
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="_enumOnlineType.Disconnect">
                                        <div class="status-item">
                                            <div class="ball status-Disconnect" data-toggle="tooltip"
                                                [title]="_enumOnlineType[device.onlineStatus]">
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="_enumOnlineType.Offline">
                                        <div class="status-item">
                                            <div class="ball status-Offline" data-toggle="tooltip"
                                                [title]="_enumOnlineType[device.onlineStatus]">
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="_enumOnlineType.Syncing">
                                        <div class="status-item">
                                            <div class="ball status-Syncing" data-toggle="tooltip"
                                                [title]="_enumOnlineType[device.onlineStatus]">
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="_enumOnlineType.Error">
                                        <div class="status-item">
                                            <div class="ball status-Error" data-toggle="tooltip"
                                                [title]="_enumOnlineType[device.onlineStatus]">
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                                <span class="ml-2">
                                    <a [routerLink]="['/app/device/devices', device.virtualId]" data-toggle="tooltip"
                                        [title]="device.virtualId">
                                        {{device.virtualName}}
                                    </a>
                                    <a *ngIf="!btnMobile.disabled && supportRemoteCtrl(device)"
                                        [routerLink]="['/app/device/remotectrl', device.virtualId]"
                                        [class.disabled]="device.onlineStatus !== _enumOnlineType.Online"
                                        href="/app/device/remotectrl" target="_blank">
                                        <i class="fab fa-mixcloud ml-2"></i>
                                    </a>
                                </span>
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_INFO_MODEL].show" class="col text-break">
                                {{device.currentSettings[constantSvc.DEVKEY_INFO_MODELDESC] ||
                                device.currentSettings[constantSvc.DEVKEY_INFO_MODEL]}}
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_NET_LAN_MAC].show" class="col text-break">
                                <span
                                    *ngIf="device.currentSettings[constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !device.currentSettings[constantSvc.DEVKEY_NET_LAN_CONNECTED]; else templateMacNoWifi">
                                    {{device.currentSettings[constantSvc.DEVKEY_NET_WIFI_MAC]}}
                                    <span class="badge badge-info">
                                        <i class="fas fa-wifi"></i>
                                    </span>
                                </span>
                                <ng-template #templateMacNoWifi>
                                    {{device.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC]}}
                                </ng-template>
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_NET_LAN_IP].show" class="col text-break">
                                <span
                                    *ngIf="device.currentSettings[constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !device.currentSettings[constantSvc.DEVKEY_NET_LAN_CONNECTED]; else templateIPNoWifi">
                                    {{device.currentSettings[constantSvc.DEVKEY_NET_WIFI_IP]}}
                                    <span class="badge badge-info">
                                        <i class="fas fa-wifi"></i>
                                    </span>
                                </span>
                                <ng-template #templateIPNoWifi>
                                    {{device.currentSettings[constantSvc.DEVKEY_NET_LAN_IP]}}
                                </ng-template>
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_INFO_FW_VERSION].show" class="col text-break">
                                {{device.currentSettings[constantSvc.DEVKEY_INFO_FW_VERSION]}}
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_INFO_APKVERSION].show" class="col text-break">
                                {{device.currentSettings[constantSvc.DEVKEY_INFO_APKVERSION]}}
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_FAKE_HEARTBEAT].show" class="col text-break">
                                {{device.currentSettings[constantSvc.DEVKEY_FAKE_HEARTBEAT] | date:'yyyy-MM-dd HH:mm:ss
                                Z'}}
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE].show"
                                class="col text-break">
                                {{ device.currentSettings[constantSvc.DEVKEY_INFO_WARRANTY_ENDDATE] | date:'yyyy-MM-dd'
                                }}
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_FAKE_GROUPNAME].allowShow && _tableColMap[constantSvc.DEVKEY_FAKE_GROUPNAME].show"
                                class="col text-break">
                                {{device.currentSettings[constantSvc.DEVKEY_FAKE_GROUPNAME]}}
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_FAKE_SCEP_STATUS].allowShow && _tableColMap[constantSvc.DEVKEY_FAKE_SCEP_STATUS].show"
                                class="col text-break" style="font-size:0.8rem">
                                <div>
                                    <i class="fas fa-credit-card" data-toggle="tooltip" title="802.1x"></i>
                                    {{device.scep.inUse?.certName || 'Disable'}}
                                </div>
                                <ng-template [ngIf]="device.scep.inUse?.certName">
                                    <div>
                                        <i class="fas fa-key" data-toggle="tooltip" title="EAP CA enroll status"></i>
                                        {{device.scep.inUse?.state}}
                                    </div>
                                    <div>
                                        <i class="fas fa-calendar-times" data-toggle="tooltip"
                                            title="EAP CA expiry date"></i>
                                        {{ device.scep.inUse?.notAfter | date:'yyyy-MM-dd' }}
                                    </div>
                                </ng-template>
                            </div>
                            <div *ngIf="_tableColMap[constantSvc.DEVKEY_APPMETADATA].allowShow && _tableColMap[constantSvc.DEVKEY_APPMETADATA].show"
                                class="col text-break">
                                <ng-template
                                    [ngIf]="device.currentSettings[constantSvc.DEVKEY_APPMETADATA]?.[constantSvc.DEVKEY_APPMETADATA_KEY_APPFEEDBACK]">
                                    <span class="dropdown mr-2">
                                        <i class="fas fa-caret-down" data-toggle="dropdown" aria-expanded="false"></i>
                                        <div class="dropdown-menu dropdown-menu-md">
                                            {{
                                            device.currentSettings[constantSvc.DEVKEY_APPMETADATA][constantSvc.DEVKEY_APPMETADATA_KEY_APPFEEDBACK]
                                            | json }}
                                        </div>
                                    </span>
                                    {{
                                    reduceAppFeedbackMetadata(device.currentSettings[constantSvc.DEVKEY_APPMETADATA][constantSvc.DEVKEY_APPMETADATA_KEY_APPFEEDBACK])
                                    }}
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template [ngSwitchCase]="_enumViewType.grid">
            <div class="d-flex flex-wrap">
                <!-- devices -->
                <div *ngFor="let device of _displayDevListByPage" class="card" draggable="true"
                    (dragstart)="onDragStart($event)">
                    <div class="card-header d-flex align-items-center">
                        <input [id]="device.virtualId" type="checkbox" class="cmn-checkbox" [checked]="device.isSelect"
                            (change)="selectDevice(device, $event.target.checked)" />
                        <label [for]="device.virtualId">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Select device {{device?.virtualName}}</span>
                        </label>
                        <span data-toggle="tooltip" title="{{device?.virtualName}}" class="text-truncate ml-2">
                            {{ device?.virtualName }}
                        </span>
                        <span [ngSwitch]="device.onlineStatus" class="ml-auto">
                            <ng-template [ngSwitchCase]="_enumOnlineType.Online">
                                <div class="ball status-Online" data-toggle="tooltip"
                                    [title]="_enumOnlineType[device.onlineStatus]">
                                </div>
                            </ng-template>
                            <ng-template [ngSwitchCase]="_enumOnlineType.Disconnect">
                                <div class="ball status-Disconnect" data-toggle="tooltip"
                                    [title]="_enumOnlineType[device.onlineStatus]">
                                </div>
                            </ng-template>
                            <ng-template [ngSwitchCase]="_enumOnlineType.Offline">
                                <div class="ball status-Offline" data-toggle="tooltip"
                                    [title]="_enumOnlineType[device.onlineStatus]">
                                </div>
                            </ng-template>
                            <ng-template [ngSwitchCase]="_enumOnlineType.Syncing">
                                <div class="ball status-Syncing" data-toggle="tooltip"
                                    [title]="_enumOnlineType[device.onlineStatus]">
                                </div>
                            </ng-template>
                            <ng-template [ngSwitchCase]="_enumOnlineType.Error">
                                <div class="ball status-Error" data-toggle="tooltip"
                                    [title]="_enumOnlineType[device.onlineStatus]">
                                </div>
                            </ng-template>
                        </span>
                    </div>
                    <div class="card-body">
                        <div class="center" [class.overlay]="!_screenshotInfo[device.virtualId]?.url">
                            <img [src]="_screenshotInfo[device.virtualId]?.url"
                                [class.active]="_screenshotInfo[device.virtualId]?.url">
                        </div>
                        <div class="footer d-flex align-items-center mx-2">
                            <i class="fas fa-clock mr-1"></i>
                            {{ device?.currentSettings[constantSvc.DEVKEY_FAKE_HEARTBEAT] | date:'yyyy-MM-dd
                            HH:mm:ssZ' }}
                            <div class="ml-auto">
                                <a *ngIf=" !btnMobile.disabled && supportRemoteCtrl(device)"
                                    [routerLink]="['/app/device/remotectrl', device.virtualId]"
                                    [class.disabled]="device.onlineStatus !== _enumOnlineType.Online"
                                    href="/app/device/remotectrl" target="_blank">
                                    <i class="fab fa-mixcloud mr-2 detail-link"></i>
                                </a>
                                <a [routerLink]="['/app/device/devices', device.virtualId]">
                                    <i class="fas fa-angle-double-right ml-1 detail-link"></i>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<ng-template #templateLoading>
    <div class="loading">
        <p>Please wait...</p>
        <div class="loading-box"></div>
    </div>
</ng-template>
<div class="bottom-bar mt-1">
    <div class="d-flex">
        <small class="amount-block">
            <span class="fas fa-check-square green-3" data-toggle="tooltip" title="selected device(s)"></span>
            <span>{{ _selectedDeviceAmountByFilter }}</span>
            <ng-container *ngIf="_isAdvanceFilterApplied">
                <span class="fas fa-filter green-3" data-toggle="tooltip" title="filtered device(s)"></span>
                <span>{{ _displayDevListByFilter.length }}</span>
            </ng-container>
            <ng-container *ngIf="!_isAdvanceFilterApplied">
                <span class="txt-icon bg-green-3">All</span>
                <span>{{ _displayDevListByDevGroup.length }}</span>
            </ng-container>
        </small>
        <div class="ml-auto">
            <na-pager [currentPage]="_currentPage" [numberInPage]="_numberInPage"
                [total]="_displayDevListByFilter.length" (onPageChanged)="onPageChange($event)">
            </na-pager>
        </div>
    </div>
</div>
<!-- dynamic multi-device function form -->
<div id="devPickupFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template dev-func-host></ng-template>
</div>
<div id="devLabelFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
    <ng-template dev-label-func-host></ng-template>
</div>