import { Component, OnInit } from '@angular/core';

import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { AutoUnsubscribeComponent } from '../../../../../app/content/virtual/auto-unsubscribe.component';
import { DeviceInfo, DeviceType } from '../../../../../app/content/device/data/device-info';
import { HelperLib } from '../../../../../app/lib/common/helper.lib';

@Component({
    templateUrl: './script-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', '../../dev-func.style.css']
})
export class ScriptActionSubFormComponent extends AutoUnsubscribeComponent implements DevFuncActionInterface, OnInit {
    readonly PWD_MIN_LEN: number = 4;

    actionCompleteHandler?: (result: any) => void;
    actionStatusUpdateHandler?: (isValid: boolean) => void;
    actionCommunicateHandler?: (data: any) => void;

    _enumDevType: typeof DeviceType = DeviceType;
    _devType: DeviceType;

    _devLinuxList: DeviceInfo[] = [];
    _devAndroidList: DeviceInfo[] = [];
    _devList: DeviceInfo[] = [];

    _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._actionData.subject = this._actionData.subject || 'Custom Instructions';
            this._actionData.linux = this._actionData.linux || { devices: [], instruction: ''};
            this._actionData.and = this._actionData.and || { devices: [], instruction: ''};
            this._actionData.linux.devices = [];
            this._actionData.and.devices = [];

            this._actionData.deviceList.forEach((dev: DeviceInfo) => {
                if (!this._actionData.bSpecific && !dev.isSelect) {
                    return;
                }

                const devType: DeviceType = HelperLib.getDeviceType(dev.currentSettings[this.constantSvc.DEVKEY_INFO_FW_FAMILY]);
                switch (devType) {
                    case DeviceType.and:
                        this._actionData.and.devices.push(dev);
                        break;
                    case DeviceType.linux:
                        this._actionData.linux.devices.push(dev);
                        break;
                }
            });

            this.selectDeviceType(this._actionData.lastDevType || DeviceType.linux);
        }
    }

    constructor(private constantSvc: ConstantService) {
        super();
    }

    ngOnInit(): void {
        this.validate();
    }

    selectDeviceType(devType: DeviceType): void {
        if (this._devType != devType) {
            this._devType = devType;
            this._actionData.lastDevType = this._devType;

            switch (this._devType) {
                case DeviceType.and:
                    this._devList = this._actionData.and.devices;
                    break;
                case DeviceType.linux:
                    this._devList = this._actionData.linux.devices;
            };
        }
    }

    changeInstLinux(content: string): void {
        this._actionData.linux.instruction = content;
        this.validate();
    }

    changeInstAndroid(content: string): void {
        this._actionData.and.instruction = content;
        this.validate();
    }

    private validate(): void {
        if (this._devLinuxList.length > 0 && !this._actionData.linux.instruction) {
            return this.actionStatusUpdateHandler(false);
        }

        if (this._devAndroidList.length > 0 && !this._actionData.and.instruction) {
            return this.actionStatusUpdateHandler(false);
        }
        
        this.actionStatusUpdateHandler(true);
    }
}