import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DeviceInfo } from '../../device/data/device-info';
import { DialogPage, IClass } from '../../../lib/common/common.data';
import { LicenseBaseDlgComponent } from './license-base-dlg.component';
import { Logger } from '../../../lib/common/logger';

@Component({
    templateUrl: './license-dlg-import.component.html',
    styleUrls: ['./license-dlg-import.component.css', './license-dlg.style.css']
})
export class LicenseDlgImportComponent extends LicenseBaseDlgComponent implements OnInit, OnDestroy, IClass {
    className: string;
    @Input()
    set devices(devs: DeviceInfo[]) {
        if (!devs) {
            return;
        }

        this._devices = devs;
    }

    private _filePickerRef: ElementRef;
    @ViewChild('filePicker')
    set filePicker(holder: ElementRef) {
        if (holder) {
            this._filePickerRef = holder;
        }
    }

    private _licenseFormatError: string;
    private _bDragEnter: boolean = false;
    private _licenseFileName: string;
    private _licenseAllocateAmount: number = 0;
    private _licenseTotalAmount: number = 0;
    private _unusedLicenseList: string[] = [];
    private _licenseCodeMap: { [code: string]: boolean } = {};
    private _devLicenseMap: { [virtualDeviceID: string]: { dev: DeviceInfo, licenseCodes: string[] } } = {};
    private _devLicenseImportErrorList: { device: DeviceInfo, errorMessageList: { licenseKey: string, errorMessage: string }[] }[] = [];

    ngOnInit(): void {
        this.className = 'License-import-form';
        super.ngOnInit();

        if (this._devices.length > 0) {
            const comparedAccountID: string = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountID : this.accountSvc.accountID;
            this._legalDevices = this._devices.filter(d => d.virtualDeviceOwnerID === comparedAccountID);
            this._legalDevices.forEach((d: DeviceInfo) => {
                this._devLicenseMap[d.virtualId] = { dev: d, licenseCodes: [] }
            });
                
            this.goNext();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    allowSubmit(): boolean {
        if (this._page === DialogPage.confirm) {
            for (const virtualDeviceID of Object.keys(this._devLicenseMap)) {
                if (this._devLicenseMap[virtualDeviceID].licenseCodes.length > 0) {
                    return true;
                }
            }
            return false;
        }

        return super.allowSubmit();
    }

    goNext(): void {
        if (this._page === DialogPage.confirm) {
            this._page = DialogPage.submit;

            const requests: { licenseKeys: string[], targetDevice: DeviceInfo }[] = Object.keys(this._devLicenseMap).map((virtualDeviceID: string) => {
                return {
                    licenseKeys: this._devLicenseMap[virtualDeviceID].licenseCodes,
                    targetDevice: this._devLicenseMap[virtualDeviceID].dev
                }
            });

            this._devLicenseImportErrorList = [];
            this.licenseSvc.importAndAssignLicense(requests).subscribe((res: { hasNext: boolean, result?: { targetDevice: DeviceInfo, isFault: boolean, errorMessage?: any }[] }) => {
                if (!res.hasNext) {
                    if (res.result) {
                        res.result.forEach(result => {
                            if (result.isFault) {
                                //only consider import error now.
                                if (result.errorMessage instanceof Array) {
                                    this._devLicenseImportErrorList.push({
                                        device: result.targetDevice,
                                        errorMessageList: result.errorMessage
                                    });
                                }
                            }
                        });
                    }
                    super.goNext();
                }
            });
        }
        else {
            super.goNext();
        }
    }

    private triggerFilePicker(): void {
        this._filePickerRef.nativeElement.click();
    }

    private fileDrop(e: DragEvent): void {
        this.cancelDefault(e);
        this._licenseFormatError = null;
        this._bDragEnter = false;
        if (e.dataTransfer.items) {
            if (e.dataTransfer.items.length === 1) {
                if (e.dataTransfer.items[0].kind === 'file') {
                    const file = e.dataTransfer.items[0].getAsFile();
                    this.extractFileContent(file);
                }
            }
        }
        else {
            if (e.dataTransfer.files.length === 1) {
                this.extractFileContent(e.dataTransfer.files[0]);
            }
        }
    }

    private fileDragEnter(e: any): boolean {
        this._bDragEnter = true;
        return this.cancelDefault(e);
    }

    private fileDragLeave(e: any): boolean {
        this._bDragEnter = false;
        return this.cancelDefault(e);
    }

    private fileDragOver(e: any): boolean {
        this._bDragEnter = true;
        return this.cancelDefault(e);
    }

    private cancelDefault(e: any): boolean {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    private fileChange(e: any): void {
        if (e.target.files.length === 1) {
            this.extractFileContent(e.target.files[0]);
        }
    }

    private extractFileContent(f: any): void {
        const reader = new FileReader();
        reader.onloadend = () => {
            const content = reader.result as string;
            const licenseCodeList = content.trim().split(/[,\n]+/).sort((ca: string, cb: string) => ca > cb ? 1 : -1);
            //check
            for (let licenseCode of licenseCodeList) {
                if (!this.isLicenseCodeFormatCorrect(licenseCode)) {
                    this._licenseFormatError = 'License format error (' + f.name + ')';
                    break;
                }
            }

            if (this._licenseFormatError) {
                return;
            }

            this._licenseTotalAmount = licenseCodeList.length;
            this._licenseFileName = f.name;
            licenseCodeList.forEach((code: string) => {
                this._licenseCodeMap[code] = false;
            });

            if (licenseCodeList && licenseCodeList.length > 0) {
                let index = 0;
                for (const dev of this._legalDevices) {
                    this._devLicenseMap[dev.virtualId].licenseCodes.push(licenseCodeList[index]);
                    this._licenseCodeMap[licenseCodeList[index]] = true;
                    index++;
                    if (index === licenseCodeList.length) {
                        break;
                    }
                }
            }

            this.refreshUnusedLicense();
        };
        reader.onerror = (error: any) => {
            Logger.logError('LicenseImport', 'extractFileContent', 'Extract failed ', error);
        };
        reader.readAsText(f);
    }

    private isLicenseCodeFormatCorrect(licenseCode: string): boolean {
        const split_codes = licenseCode.match(/(\w{5})+/g);
        return split_codes && split_codes.length > 4 ? true : false;
    }

    protected isPageValid(page: DialogPage): boolean {
        if (page === DialogPage.prepare) {
            //check if devices length > 0
            return this._legalDevices.length > 0 ? true : false;
        }

        return super.isPageValid(page);
    }

    private removeLicense(virtualDeviceID: string, licenseCode: string): void {
        const i = this._devLicenseMap[virtualDeviceID].licenseCodes.indexOf(licenseCode);
        if (i >= 0) {
            this._licenseCodeMap[licenseCode] = false;
            this._devLicenseMap[virtualDeviceID].licenseCodes.splice(i, 1);
            this.refreshUnusedLicense();
        }
    }

    private addLicense(virtualDeviceID: string, licenseCode: string): void {
        this._licenseCodeMap[licenseCode] = true;
        this._devLicenseMap[virtualDeviceID].licenseCodes.push(licenseCode);
        this.refreshUnusedLicense();
    }

    private refreshUnusedLicense(): void {
        this._unusedLicenseList = Object.keys(this._licenseCodeMap).filter((code: string) => this._licenseCodeMap[code] === false);
        this._licenseAllocateAmount = this._licenseTotalAmount - this._unusedLicenseList.length;
    }
}