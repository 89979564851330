import { Component } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { ConfigDialogPage, DLG_NAME_SCRIPT } from '../base/base-config-data';
import { LicenseScopeType } from '../../../../app/content/license/license.data';
import { HelperLib } from '../../../lib/common/helper.lib';
import { DeviceInfo } from '../../../../app/content/device/data/device-info';
import { CustomResponse } from '../../../../app/lib/common/common.data';
import { Logger } from '../../../../app/lib/common/logger';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css']
})
export class ScriptFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DLG_NAME_SCRIPT;
        this._licenseScopeType = LicenseScopeType.taskConfig;

        super.ngOnInit();
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        switch (this._page) {
            case ConfigDialogPage.action:
                {
                    this._page = ConfigDialogPage.confirm;
                    this.submit();
                }
                break;
        }

        super.goNext();
    }

    protected submit(): void {
        super.submit();

        const obs = [this._actionData.linux, this._actionData.and].map((data: { devices: DeviceInfo[], instruction: string }) => of(data).pipe(
            concatMap((data: { devices: DeviceInfo[], instruction: string }) => {
                if (data.devices.length > 0 && data.instruction) {
                    return this.devSvc.batchDoCustomAction(this._bSpecific ? [data.devices[0]] : data.devices, {
                        actionID: this.constantSvc.TASKTYPE_SCRIPT,
                        subject: 'Custom script',
                        resourceData: { cmd: data.instruction }
                    });
                }

                return of(CustomResponse.success());
            })
        ));

        of(null).pipe(
            concatMap(() => forkJoin(obs))
        ).subscribe((results: CustomResponse<any>[]) => {
            Logger.logInfo('Script', 'submit', 'script submit result: ', results);
            this._page++;

            this._errorMessage = results.map(result =>  HelperLib.getErrorMessage(result)).filter(error => error).join(', ');

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(results);
            }
        });
    }
}