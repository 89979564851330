<div class="p-2">
    <div>
        Run local scripts on the target devices <ng-container *ngIf="!_actionData.bSpecific">of different device types</ng-container>.
    </div>
    <div class="mt-3 pl-0">
        <div class="form-group row">
            <label for="col-dev-type" class="col-12 col-md-4 col-form-label">
                Device type
            </label>
            <div id="col-dev-type" class="col-12 col-md-8">
                <button type="button" class="btn btn-sm btn-action btn-light"
                    [class.active]="_devType === _enumDevType.linux" (click)="selectDeviceType(_enumDevType.linux)">
                    Linux
                </button>
                <button *ngIf="!_actionData.bSpecific" type="button" class="btn btn-sm btn-action btn-light border-left-0"
                    [class.active]="_devType === _enumDevType.and" (click)="selectDeviceType(_enumDevType.and)">
                    Android
                </button>
            </div>
        </div>
        <div class="form-group row">
            <label for="col-dev-type" class="col-12 col-md-4 col-form-label">
                Script
            </label>
            <div id="col-dev-type" class="col-12 col-md-8">
                <textarea *ngIf="_devType === _enumDevType.linux" name="instLinux"
                    [ngModel]="_actionData.linux.instruction" (ngModelChange)="changeInstLinux($event)" [attr.required]="_devList.length > 0"
                    class="w-100 p-1">
                    </textarea>
                <div *ngIf="_devType === _enumDevType.and">
                    Not support on android devices now.
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="col-devs" class="col-12 col-md-4 col-form-label">
                Devices
            </label>
            <div class="col-12 col-md-8">
                <div *ngIf="_devList.length > 0" class="list-group my-1">
                    <div class="list-group-item list-group-header black">
                        <div class="row">
                            <div class="col title d-flex align-items-center">
                                <span class="ml-1">Device name</span>
                            </div>
                        </div>
                    </div>
                    <div class="list-group-content list-group-content-modal">
                        <div *ngFor="let dev of _devList;" class="list-group-item list-group-item-action">
                            <div class="row align-items-center">
                                <div class="col d-flex align-items-center text-break">
                                    <span class="ml-1">{{ dev?.virtualName }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>